import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ProjectService } from '../../../services/project.service';
import { UploadService } from '../../../services/upload.service';

import { Upload } from '../../../entities/Upload';
import { Project } from '../../../entities/Project';

@Component({
  selector: 'app-project-add',
  templateUrl: './project-add.component.html',
  styleUrls: ['./project-add.component.css']
})
export class ProjectAddComponent implements OnInit {

  form: FormGroup;
  upload: Upload;
  selectedFiles: FileList;

  constructor(private projectService: ProjectService, private uploadService: UploadService) { }

  ngOnInit() {
    this.form = new FormGroup({
      projectName: new FormControl(null, [Validators.required]),
      categoryName: new FormControl(null, [Validators.required]),
      order: new FormControl(0, [Validators.required]),
    });
  }

  detectImages(event) {
    this.selectedFiles = event.target.files;
  }

  async onSubmit() {
    const { projectName, categoryName, order } = this.form.value;
    const project: Project = {
      projectName, categoryName, order
    };

    this.upload = new Upload(this.selectedFiles.item(0));
    this.uploadService.pushUpload(this.upload)
      .then(async response => {
        project.imageUrl = await response.ref.getDownloadURL();
        await this.projectService.add(project);
      });
  }

}
