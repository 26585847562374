import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Upload } from '../../../entities/Upload';
import { Project } from '../../../entities/Project';

import { UploadService } from '../../../services/upload.service';
import { ProjectService } from '../../../services/project.service';

@Component({
  selector: 'app-project-update',
  templateUrl: './project-update.component.html',
  styleUrls: ['./project-update.component.css']
})
export class ProjectUpdateComponent implements OnInit {

  @ViewChild('updateModal', { static: true })
  private updateModal: NgbModal;

  private selectedFiles: FileList;
  form: FormGroup;
  private upload: Upload;
  private project: Project;

  constructor(private modalService: NgbModal, private projectService: ProjectService, private uploadService: UploadService) { }

  ngOnInit() {
  }

  setProject(project: Project) {
    this.project = project;
    return this;
  }

  show() {
    if (this.project) {
      this.form = new FormGroup({
        projectName: new FormControl(this.project.projectName, [Validators.required]),
        categoryName: new FormControl(this.project.categoryName, [Validators.required]),
        order: new FormControl(this.project.order, [Validators.required]),
      });
      this.modalService.open(this.updateModal);
    }
  }

  detectImages(event) {
    this.selectedFiles = event.target.files;
  }

  async onSubmit() {
    let imageUrl = this.project.imageUrl;
    const { projectName, categoryName, order } = this.form.value;

    // Update imageUrl if selected new image
    if (this.selectedFiles) {
      this.upload = new Upload(this.selectedFiles.item(0));
      const uploadResponse = await this.uploadService.pushUpload(this.upload);
      imageUrl = await uploadResponse.ref.getDownloadURL();
    }

    // Update project
    const project: Project = { ...this.project, categoryName, projectName, order, imageUrl };
    this.projectService.update(project)
      .then(() => this.modalService.dismissAll())
      .catch(err => alert(err.message))
      .finally(() => this.resetAll());
  }

  private resetAll() {
    this.form = undefined;
    this.upload = undefined;
    this.project = undefined;
    this.selectedFiles = undefined;
  }
}
