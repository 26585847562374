import {Injectable} from '@angular/core';

import {Project} from '../entities/Project';
import {ProjectService} from './project.service';

@Injectable({
  providedIn: 'root'
})
export class SliderService {
  private projects: Project[] = [];
  activeIndex = 0;

  constructor(private projectService: ProjectService) {
    this.projectService.getAll()
      .subscribe((snapshot) => {
        this.projects = snapshot.docs.map(e => ({
          id: e.id, ...e.data(),
        }) as Project);
      });
  }

  get slider() {
    return this.sliderLength > 0 ? this.projects[this.activeIndex] : null;
  }

  get sliderLength() {
    return this.projects.length;
  }

  changePrevSlider() {
    this.activeIndex = this.activeIndex > 0 ? this.activeIndex - 1 : this.sliderLength - 1;
  }

  changeNextSlider() {
    this.activeIndex = this.activeIndex < this.sliderLength - 1 ? this.activeIndex + 1 : 0;
  }
}
