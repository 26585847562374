import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Project } from '../entities/Project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private projectsCollection: AngularFirestoreCollection<Project>;

  constructor(private afs: AngularFirestore) {
    this.projectsCollection = afs.collection<Project>('projects', ref => ref.orderBy('order'));
  }

  add(project: Project) {
    return this.projectsCollection.add(project);
  }

  getAll() {
    return this.projectsCollection.get();
  }

  getAllWithChanges() {
    return this.projectsCollection.snapshotChanges();
  }

  delete(projectId: string) {
    const docRef = this.projectsCollection.doc(projectId);
    return docRef.delete();
  }

  update(project: Project) {
    const docRef = this.projectsCollection.doc(project.id);
    delete project.id;
    return docRef.update(project);
  }
}
