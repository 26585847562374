import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  popupMessage = 'Erkan Günaydın was born in 1991 in Istanbul. A graphic designer with a passion for art, who ' +
    'loves traveling and combines all of his work for visual communication. He uses forms aiming at simplicity in ' +
    'his works and always tries to give a meaning and a message. Günaydın studied web design and programming in high ' +
    'school. After graduating from university in 2012, he started to work for an international company. During this ' +
    'period, he participated in many competitions and won degrees and awards. Today, he is still involved in many ' +
    'national and international projects.';

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  onLogoClick(content) {
    this.modalService.open(content, { size: 'xl', centered: true, windowClass: 'custom-modal' });
  }

}
