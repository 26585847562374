import { Component, OnInit, ViewChild } from '@angular/core';

import { ProjectService } from '../../../services/project.service';
import { Project } from '../../../entities/Project';
import { ProjectUpdateComponent } from '../project-update/project-update.component';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent implements OnInit {

  @ViewChild('updateForm', { static: true })
  private updateForm: ProjectUpdateComponent;

  projects: Project[] = [];

  constructor(private projectService: ProjectService) { }

  ngOnInit() {
    this.projectService.getAllWithChanges()
      .subscribe(data => {
        this.projects = data.map(e => ({
          id: e.payload.doc.id, ...e.payload.doc.data()
        }) as Project);
      });
  }

  onDelete(projectId: string) {
    this.projectService.delete(projectId)
      .catch(err => alert(err.message));
  }

  onUpdate(project: Project) {
    this.updateForm.setProject(project).show();
  }

}
