import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Services
import { SliderService } from './services/slider.service';

// Layout components
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';

// Homepage
import { HomeComponent } from './views/home/home.component';
import { SliderComponent } from './components/home/slider/slider.component';

// Auth components
import { LoginComponent } from './views/login/login.component';
import { AdminComponent } from './views/admin/admin.component';
import { ProjectAddComponent } from './components/admin/project-add/project-add.component';
import { ProjectListComponent } from './components/admin/project-list/project-list.component';
import { ProjectUpdateComponent } from './components/admin/project-update/project-update.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    HomeComponent,
    LoginComponent,
    AdminComponent,
    ProjectAddComponent,
    ProjectListComponent,
    ProjectUpdateComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    // Firebase modules
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFirestoreModule,
    AngularFireStorageModule
  ],
  providers: [
    SliderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
