import { Injectable } from '@angular/core';

import { AngularFireStorage } from '@angular/fire/storage';

import { Upload } from '../entities/Upload';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private basePath = '/uploads';

  constructor(private storage: AngularFireStorage) {}

  pushUpload(upload: Upload) {
    const path = `${this.basePath}/${upload.file.name}`;
    const fileRef = this.storage.ref(path);
    const uploadTask = fileRef.put(upload.file);

    uploadTask.percentageChanges()
      .subscribe(progress => upload.progress = progress);

    return uploadTask;
  }
}
