import { Component, OnInit } from '@angular/core';

import { SliderService } from '../../../services/slider.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(public sliderService: SliderService) { }

  ngOnInit() {
  }

  onPrev() {
    this.sliderService.changePrevSlider();
  }

  onNext() {
    this.sliderService.changeNextSlider();
  }

}
